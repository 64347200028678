import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import dev from '../images/services/dev.svg';
import design from '../images/services/design.svg';
import sprint from '../images/services/sprint.svg';
import consulting from '../images/services/consulting.svg';

const Services = () => (
  <Layout>
    <SEO title="Serviços" />
    <section className="services">
      <div className="columns">
        <div className="service column is-half">
          <figure className="service__title-group">
            <img className="service__icon" src={dev} alt="Desenvolvimento"/>
            <figcaption className="service__title">Desenvolvimento de aplicações para Android e iOS</figcaption>
          </figure>

          <article className="service__description">
            Com a experiência prévia em produtos de tecnologia com milhares de usuários, te ajudamos a construir seu projeto com as melhores práticas de desenvolvimento, testes, arquitetura, deployment e release disponíveis no mercado. Você está em boas mãos, deixa com a gente!
          </article>
        </div>

        <div className="service column is-half">
          <figure className="service__title-group">
            <img className="service__icon" src={design} alt="Design"/>
            <figcaption className="service__title">Design de experiência de usuário para aplicações Android e iOS</figcaption>
          </figure>
          <article className="service__description">
            Criar experiências de qualidade para mobile é um desafio cada vez maior para o sucesso de um produto, e sabemos como te ajudar nesse aspecto.
            Construímos interfaces que as pessoas querem usar, sempre com o embasamento correto, solidez, clareza e muito, muito teste.
          </article>
        </div>
      </div>

      <div className="columns">
        <div className="service column">
          <figure className="service__title-group">
            <img className="service__icon" src={sprint} alt="Design Sprint"/>
            <figcaption className="service__title">Resolvendo problemas através de Design Sprints</figcaption>
          </figure>

          <article className="service__description">
            O Design Sprint promove a resolução de problemas através de práticas de design thinking, desenho, prototipação e testes com usuários, de forma coletiva, objetiva e ágil.
            Temos o que é necessário para te ensinar como Design Sprints funcionam e como incorporá-los na sua rotina de trabalho!
          </article>
        </div>

        <div className="service column">
          <figure className="service__title-group">
            <img className="service__icon" src={consulting} alt="Consultoria"/>
            <figcaption className="service__title">Workshops, treinamentos e consultoria</figcaption>
          </figure>
          <article className="service__description">
            Provemos workshops, treinamentos e consultoria para pessoas, empresas, startups ou times que queiram aprender mais sobre desenvolvimento, design, métricas e construção de produtos digitais para mobile.
            Conta pra gente, o que você quer saber mais? :)
          </article>
        </div>
      </div>
    </section>    
  </Layout>
);

export default Services;

